import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinNames',
})
export class JoinNamesPipe implements PipeTransform {
  transform(value: any[], property: string, separator: string = ', '): string {
    if (!Array.isArray(value) || value.length === 0) {
      return '';
    }
    
    return value
      .filter(item => item && item.hasOwnProperty(property)) // Filter out items without the property
      .map(item => item[property])
      .join(separator);
  }
}
