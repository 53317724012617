<section class="sticky-top" *ngIf="isMobileView">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container py-3 d-block">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col text-left">
          <span class="navbar-toggler-icon" (click)="toggleNavbar()"></span>
        </div>
        <div class="col text-center">
          <a class="navbar-brand mx-auto" routerLink="/home">
            <img src="assets/images/f1.png" alt="Logo" class="logo" width="120" />
          </a>
        </div>
        <div class="col text-end">
          <div class="navbar-icons">
            <button class="btn btn-link p-1 p-lg-2" aria-label="Search" (click)="toggleSearchBar();">
              <img src="assets/images/search.svg" alt="Search" class="search-icon" />
            </button>
            <button type="button" class="btn btn-md p-1 p-lg-3 btn-link position-relative" (click)="openCart()">
              <img src="assets/images/shopping-cart.svg" alt="Shopping Cart" class="shopping-cart" style="width: 20px; height: 20px;" />
              <span *ngIf="cartQuantity > 0" class="position-absolute top-25 start-80 translate-middle badge rounded-pill bg-danger">
                {{ cartQuantity }}
                <span class="visually-hidden">items in cart</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="collapse navbar-collapse" [ngClass]="{'show': !isNavbarCollapsed}">
            <ul class="navbar-nav mr-auto">
              <li *ngIf="isAuthenticated" class="nav-item text-center">
                <img [src]="profile?.image ? profile?.image : './assets/images/avatars/2.jpg'" class="rounded-circle" width="60" height="60" alt="Profile">
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link text-dark" routerLink="/profile" (click)="toggleNavbar()">Welcome, {{profile?.name}}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/" (click)="toggleNavbar()">Home</a>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/profile" (click)="toggleNavbar()">My Profile</a>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/order" (click)="toggleNavbar()">My Orders</a>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/wishlist" (click)="toggleNavbar()">My Wishlist</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/catalog" (click)="toggleNavbar()">Catalog</a>
              </li>
            </ul>
            <ul class="navbar-nav ml-auto">
              <li *ngIf="!isAuthenticated" class="nav-item">
                <button class="btn btn-primary me-2" routerLink="/login" (click)="toggleNavbar()">Login</button>
                <button class="btn btn-outline-primary" routerLink="/register" (click)="toggleNavbar()">Register</button>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" (click)="logout()">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row collapse-search" id="searchBar">
        <div class="col-12">
          <div class="collapse c-navbar-collapse" id="navbarNav" [ngClass]="{'show': !isSearchBarCollapsed}">
            <form class="form-inline my-2 my-lg-0 px-2 w-100" (submit)="onSearch($event)">
              <input class="form-control mr-sm-2 w-100" type="search"  [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" placeholder="Search" (input)="onInputChange($event)" aria-label="Search" />
            </form>
          </div>
        </div>
      </div>
      <!-- <div class="row collapse-cart" id="cartBar">
        <div class="col-12">
          <div class="collapse c-navbar-collapse" id="navbarNav" [ngClass]="{'show': !isCartCollapsed}" @cartAnimation>
            <app-mini-cart *ngIf="showCart" @cartAnimation></app-mini-cart>
          </div>
        </div>
      </div> -->
    </div>
  </nav>
  <div class="overlay" [ngClass]="{'show': !isNavbarCollapsed}" (click)="toggleNavbar()"></div>
  <div class="overlay" [ngClass]="{'show': !isSearchBarCollapsed}" (click)="toggleSearchBar()"></div>
</section>

<section class="sticky-top" *ngIf="!isMobileView">
  <div class="container">
    <header class="d-flex flex-nowrap align-items-center justify-content-center justify-content-lg-between gap-3 py-2 border-bottom">
      <div class="col-lg-3 mb-lg-0">
        <a routerLink="/home" class="d-inline-flex link-body-emphasis text-decoration-none">
          <img src="assets/images/f1.png" alt="Logo" class="logo" style="width: 100%; max-width: 150px;" />
        </a>
      </div>
      <div class="search flex-grow-1">
        <form class="d-flex" (ngSubmit)="onSearch($event)">
          <div class="input-group w-100 border rounded">
            <input type="search" class="form-control focus-ring focus-ring-light border-0 fs-6 p-2" [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" placeholder="Search" (input)="onInputChange($event)" aria-label="Search" />
            <button type="button" class="btn btn-white focus-ring focus-ring-light">
              <img src="assets/images/search.svg" alt="Search" class="search-icon" />
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="!isAuthenticated" class="d-flex justify-content-between align-items-center text-end">
        <button type="button" class="btn btn-md px-4 py-2 btn-primary me-2" routerLink="/login">Login</button>
        <button type="button" class="btn btn-md px-4 py-2 btn-outline-primary" routerLink="/register">Register</button>
      </div>
      <div *ngIf="isAuthenticated" class="d-flex justify-content-between align-items-center text-end">
        <button type="button" class="btn btn-md p-3 btn-link position-relative" (click)="openCart()">
          <img src="assets/images/shopping-cart.svg" alt="Shopping Cart" class="shopping-cart" style="width: 20px; height: 20px;" />
          <small>
            <small *ngIf="cartQuantity > 0" class="position-absolute top-25 start-80 translate-middle badge rounded-pill bg-danger">
              {{ cartQuantity }}
              <span class="visually-hidden">items in cart</span>
            </small>
          </small>
        </button>
        <div class="nav-item py-1"><div class="vr h-100 mx-4 text-body text-opacity-75"></div></div>
        <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      </div>
    </header>
  </div>
</section>

<ng-template #userDropdown>
  <div ngbDropdown>
    <button ngbDropdownToggle class="btn btn-link text-primary text-decoration-none py-0 pe-0" aria-label="Open user menu">
      <img [src]="profile?.image ? profile?.image.url : './assets/images/avatars/2.jpg'" class="rounded-circle" width="40" height="40" alt="{{profile?.name}}" />
      <span class="ms-3">{{profile?.name}}</span>
    </button>
    <div ngbDropdownMenu class="w-auto">
      <a class="dropdown-item" role="button" (click)="openProfile()">
        <fa-icon [icon]="icons.faUser" class="me-2"></fa-icon>
        My Profile
      </a>
      <a class="dropdown-item" role="button" (click)="openOrders()">
        <fa-icon [icon]="icons.faListCheck" class="me-2"></fa-icon>
        My Orders
      </a>
      <a class="dropdown-item" role="button" (click)="openWishlist()">
        <fa-icon [icon]="icons.faHeart" class="me-2"></fa-icon>
        My Wishlist
      </a>
      <a class="dropdown-item" role="button" (click)="logout()">
        <fa-icon [icon]="icons.faRightFromBracket" class="me-2"></fa-icon>
        Logout
      </a>
    </div>
  </div>
</ng-template>
