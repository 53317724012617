<!-- Footer Area -->
<div class="container">
  <div class="row g-2 py-2">
    <div class="col">
      <!-- Placeholder for spacing or content -->
    </div>
  </div>
</div>

<div class="container">
  <div class="row py-3">
    <div class="col-12 col-lg-5 d-flex flex-column">
      <a href="https://franchise.one/" class="custom-logo-link" rel="home" aria-current="page">
        <img width="200" src="assets/images/f1.png" class="custom-logo" alt="Franchise One" decoding="async">
      </a>
      <p>
        <strong>PT Revolusi Teknologi Internasional</strong><br>
        Gedung Artha Graha SCBD<br>
        (Sudirman Central Business District)<br>
        Jl. Jend. Sudirman Kav. 52 – 53.<br>
        Jakarta Selatan. 12190
      </p>
    </div>
    <div class="col-12 col-lg-2">
      <p class="mb-1 fw-bold text-primary">Links</p>
      <nav aria-label="Footer navigation">
        <ul class="list-unstyled">
          <li class="mb-2"><a class="text-decoration-none" href="https://about.franchise.one">About Us</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-12 col-lg-3 d-flex flex-column">
      <p class="mb-1 fw-bold text-primary">Contact Us</p>
      <nav aria-label="Footer navigation">
        <ul class="list-unstyled">
          <li><a class="text-decoration-none" href="mailto:franchisor@franchise.one">franchisor&#64;franchise.one</a></li>
          <li><a class="text-decoration-none" href="mailto:franchisee@franchise.one">franchisee&#64;franchise.one</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-12 col-lg-2 d-flex flex-column mx-auto">
      <p class="mb-1 fw-bold text-primary">Follow Us</p>
      <ul class="list-unstyled d-flex flex-row">
        <li class="me-2 text-primary">
          <a href="https://www.instagram.com/franchiseone.official" title="Instagram" target="_blank">
            <img src="assets/images/instagram.svg" alt="Instagram" class="social-icon" width="24" height="24" />
          </a>
        </li>
        <li class="me-2 text-primary">
          <a href="https://www.tiktok.com/@franchise.one" title="TikTok" target="_blank">
            <img src="assets/images/tiktok.svg" alt="TikTok" class="social-icon" width="24" height="24" />
          </a>
        </li>
        <li class="me-2 text-primary">
          <a href="https://www.youtube.com/@FranchiseOne" title="YouTube" target="_blank">
            <img src="assets/images/youtube.svg" alt="YouTube" class="social-icon" width="24" height="24" />
          </a>
        </li>
        <li class="me-2 text-primary">
          <a href="https://www.linkedin.com/company/franchise-one" title="LinkedIn" target="_blank">
            <img src="assets/images/linkedin.svg" alt="LinkedIn" class="social-icon" width="24" height="24" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
